.custom-nav {
	/* background-color: #f6de1e;*/
    background-color: #3E79BC;
    display: flex;
    flex-direction: row;
    row-gap: 50px;
	font-size: 1.2rem;
}

.custom-nav  .navbar-toggler{
    color: #3E79BC;
}


.custom-nav .navbar-nav .nav-link{
    color: #F8F9FC;
}

.custom-nav .navbar-nav .nav-link .active{
    color: blue;
}

.custom-nav .navbar-nav:hover{
    color: #5c92fa;
}

.custom-nav .navbar-toggler:hover{
    color: white;
}

.custom-nav .navbar-toggler {
    background-color: white;
}

.custom-nav > .navbar-toggler-icon{
    background-color: white;
}

.custom-nav .navbar-toggler.collapsed{
    color: white;
}




/* .nav-tabs {
    --bs-nav-tabs-border-color: #08080894;
    --bs-nav-tabs-link-hover-border-color: #08080894;
	--bs-nav-tabs-link-active-color: #08080894;
    --bs-nav-tabs-link-active-bg:#08080894;
    --bs-nav-tabs-link-active-border-color:#08080894;
} */

/* path{
    fill: white;
} */