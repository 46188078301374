/* body {
    margin-top: 20px;
    margin-left: 20px;
    background: rgb(0, 0, 0, 0.6);
    color: #9aa9c1;
} */

a {
    text-decoration: none;
}

.team_title {
    color: #fff;
    margin-bottom: 60px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
}

.our-team {}

@media only screen and (max-width:768px) {
    .our-team {
        margin-bottom: 30px
    }
}

.single-team {
    margin-bottom: 10px;
}

.single-team img {
    margin-bottom: 15px;
    width: 120px;
    border-radius: 50%;
    height: 120px;
    border: 10px solid rgba(255, 255, 255, 0.1);
}

.single-team h3 {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.single-team p {
    margin-bottom: 0px;
}

.our-team .social {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.our-team .social li {
    display: inline-block;
}

.our-team .social li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 25px;
    color: #fff;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    border-radius: 30px;
    margin: 3px;
}

.our-team:hover .social li:nth-child(1) a {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.our-team:hover .social li:nth-child(2) a {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s
}

.our-team:hover .social li:nth-child(3) a {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.our-team:hover .social li:nth-child(4) a {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.our-team .social li a:hover {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

/* .github {
    background: #03042a;
} */

/* .github:hover{background: #fff; color: black!important;} */
/* .facebook{background: #1C58A1;}
    .facebook:hover{background: #fff;color:#1C58A1!important;}
    .twitter{background: #0CBCE3;}
    .twitter:hover{background: #fff;color:#0CBCE3!important;}
    .google{background: #F04537;}
    .google:hover{background: #fff;color:#F04537!important;} */

/*START BOARD DIRECTOR*/
.bod_area {
    padding-bottom: 80px;
}

.our-bod {}

@media only screen and (max-width:768px) {
    .our-bod {
        margin-bottom: 30px
    }
}

.single-bod {
    margin-bottom: 10px;
}

.single-bod img {
    margin-bottom: 15px;
    width: 120px;
    border-radius: 50%;
    height: 120px;
    border: 10px solid rgba(255, 255, 255, 0.1);
}

.single-bod h3 {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.single-bod p {
    margin-bottom: 0px;
}

.our-bod .social {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.our-bod .social li {
    display: inline-block;
}

.our-bod .social li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    color: #fff;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    border-radius: 30px;
    margin: 3px;
}

.our-bod:hover .social li:nth-child(1) a {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.our-bod:hover .social li:nth-child(2) a {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s
}

.our-bod:hover .social li:nth-child(3) a {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.our-bod:hover .social li:nth-child(4) a {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.our-bod .social li a:hover {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.title_spectial {
    color: #fff;
    text-align: center;
    font-weight: 600;
    position: relative;
    margin-bottom: 60px;
    text-transform: uppercase;
    font-size: 24px;
}

.bod_area {
    padding-bottom: 80px;
}

.section-padding {
    padding: 80px 0;
    padding-top: 100px;
}

.wow {
    display: flex;
    flex-flow: row wrap;
    column-gap: 50px;
    justify-content: center;
    max-height: 450px;
    overflow-y: auto;
}


.father{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 1%;
}

.card-custom{
    flex: 0 1 30%
    /* flex: 1 1 150px; */
    /* margin: 5px; */
    
}

@media screen and (max-width: 1200px) {
    .card-custom{
        flex: 40%
        
    }
}

@media screen and (max-width: 600px) {
    .card-custom{
        flex: 40%
        
    }
}

@media screen and (max-width: 500px) {
    .card-custom{
        flex: 50%
        
    }
}