.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
    box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
    width: 33.33%;
}

.card-body {
    position: relative;
}

.float-right {
    float: right !important;
}

.roww {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
    flex-grow: 1;
}

.father{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}